:root {
  --primary-font-family: "Quicksand", sans-serif;
  --orange-color: #E86012;
  --gray-color: #485258;
  --blue-color: #68C5D4;
  --font-regular: 400;
  --font-medium: 500;
  --font-semiBold: 600;
  --font-bold: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-semiBold);
}

strong {
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  white-space: normal;
}

.body-page-wh {
  font-family: var(--primary-font-family);
  font-weight: var(--font-medium);
  font-size: 16px;
  background: #F6FFFF;
  color: #000;
  padding-top: 100px;
}

a {
  color: #000;
}

img {
  max-width: 100%;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.container_wh {
  width: 1260px;
  margin: auto;
}
@media only screen and (max-width: 1280px) {
  .container_wh {
    width: 1040px;
  }
}
@media only screen and (max-width: 1060px) {
  .container_wh {
    width: 100%;
    padding: 0 20px;
  }
}

.row_wh {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.row_wh .col_wh_6 {
  width: calc(50% - 20px);
}

.slideshow-container .mySlides {
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.slideshow-container .mySlides.show {
  display: block;
  opacity: 1;
}
.slideshow-container .pagination_slides {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slideshow-container .dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #4BBDCF;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  opacity: 0.5;
}
.slideshow-container .active, .slideshow-container .dot:hover {
  background-color: #4BBDCF;
  opacity: 1;
}

.main_header_container_wh {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 4px 37px rgba(0, 0, 0, 0.0784313725);
  padding: 15px 20px 0 20px;
  height: 100px;
}
@media only screen and (max-width: 820px) {
  .main_header_container_wh {
    padding: 10px 20px 10px 20px;
    height: 68px;
  }
}
.main_header_container_wh .main_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_header_container_wh .main_header .brand_logo img {
  width: 184px;
}
@media only screen and (max-width: 820px) {
  .main_header_container_wh .main_header .brand_logo img {
    width: 120px;
  }
}
.main_header_container_wh .main_header .header_wrapper {
  width: calc(100% - 360px);
}
@media only screen and (max-width: 1280px) {
  .main_header_container_wh .main_header .header_wrapper {
    width: calc(100% - 250px);
  }
}
@media only screen and (max-width: 820px) {
  .main_header_container_wh .main_header .header_wrapper {
    display: none;
  }
  .main_header_container_wh .main_header .header_wrapper.active {
    display: block;
    position: absolute;
    width: 100%;
    top: 67px;
    background: #fff;
    right: 0;
    padding: 40px 20px;
    z-index: 99998;
    border-radius: 20px 20px 0 0;
  }
}
.main_header_container_wh .main_header .header_wrapper .top_header_wrapper {
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 5px 20px;
}
@media only screen and (max-width: 820px) {
  .main_header_container_wh .main_header .header_wrapper .top_header_wrapper {
    justify-content: start;
  }
}
.main_header_container_wh .main_header .header_wrapper .top_header_wrapper > span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
}
.main_header_container_wh .main_header .header_wrapper .top_header_wrapper a {
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.main_header_container_wh .main_header .header_wrapper .main_header_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  flex-wrap: wrap;
  gap: 0 15px;
}
@media only screen and (max-width: 820px) {
  .main_header_container_wh .main_header .header_wrapper .main_header_wrapper {
    align-items: start;
  }
}

.main_nav_icon {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 5px;
}
@media only screen and (max-width: 820px) {
  .main_nav_icon {
    display: block;
  }
}
.main_nav_icon span {
  height: 2px;
  width: 24px;
  display: block;
  background-color: #707070;
  border-radius: 3px;
}
.main_nav_icon span:nth-child(1) {
  position: absolute;
  top: 12px;
  right: 0;
}
.main_nav_icon span:nth-child(2) {
  position: absolute;
  top: 20px;
  right: 0;
  width: 17px;
}
.main_nav_icon span:nth-child(3) {
  position: absolute;
  top: 28px;
  right: 0;
}
.main_nav_icon.active span:nth-child(1) {
  transform: rotate(-45deg);
  top: 20px;
  background-color: var(--orange-color);
}
.main_nav_icon.active span:nth-child(2) {
  display: none;
}
.main_nav_icon.active span:nth-child(3) {
  transform: rotate(45deg);
  top: 20px;
  background-color: var(--orange-color);
}

.navigation-main {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 820px) {
  .navigation-main {
    display: block;
  }
}
.navigation-main li {
  display: inline-block;
  position: relative;
}
@media only screen and (max-width: 820px) {
  .navigation-main li {
    width: 100%;
  }
}
.navigation-main li.current {
  border-bottom: 3px solid var(--orange-color);
}
@media only screen and (max-width: 820px) {
  .navigation-main li.current {
    border-bottom: none;
  }
}
.navigation-main li.current a {
  color: var(--orange-color);
}
.navigation-main li.current svg path {
  stroke: var(--orange-color);
}
.navigation-main li > a {
  color: #000;
  text-decoration: none;
  padding: 0 35px 15px 35px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  font-weight: 600;
}
@media only screen and (max-width: 1280px) {
  .navigation-main li > a {
    padding: 0 10px 15px 10px;
  }
}
.navigation-main li ul.sub-menu {
  display: none;
  margin-top: 0;
  min-width: 300px;
  margin: 8px 0 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 99999;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 4px 37px rgba(0, 0, 0, 0.08);
}
.navigation-main li ul.sub-menu a {
  padding: 0 !important;
  text-align: left;
}
@media only screen and (max-width: 820px) {
  .navigation-main li ul.sub-menu {
    position: relative;
    background-color: inherit;
    margin: 0 0 20px;
    padding: 0 0 0 20px;
    box-shadow: none;
  }
  .navigation-main li ul.sub-menu a {
    color: #000 !important;
    text-align: left;
  }
}
.navigation-main li ul.sub-menu.active {
  display: block;
}
.navigation-main li ul.sub-menu li {
  padding: 10px 0;
  display: block;
}
.navigation-main li ul.sub-menu li a {
  display: block;
  color: #000;
}

.language_wrapper {
  display: flex;
  align-items: center;
  margin-top: -20px;
  gap: 5px;
}
@media only screen and (max-width: 820px) {
  .language_wrapper {
    margin-top: 0;
  }
}
.language_wrapper a {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  font-size: 12px;
  color: #C8C8C8;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #C8C8C8;
}
.language_wrapper a.active {
  background-color: var(--orange-color);
  border: 1px solid var(--orange-color);
  color: #fff;
}


.body-page-wh h1, .body-page-wh h2 {
  font-size: 35px;
  font-weight: var(--font-medium);
}
.body-page-wh h1 strong, .body-page-wh h2 strong {
  color: var(--blue-color);
}

.section__hero_wh {
  padding-top: 20px;
  background-image: url(../../img/bg__section_1.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.section__hero_wh .title_wrapper {
  margin-top: 80px;
}
@media only screen and (max-width: 1280px) {
  .section__hero_wh .title_wrapper {
    margin-top: 0;
  }
}
.section__hero_wh .content {
  margin-top: 40px;
}
.section__hero_wh .content p {
  font-size: 18px;
  line-height: 30px;
}
.section__hero_wh .first_col {
  padding-right: 150px;
}
@media only screen and (max-width: 1280px) {
  .section__hero_wh .first_col {
    width: calc(60% - 20px);
  }
}
@media only screen and (max-width: 820px) {
  .section__hero_wh .first_col {
    width: 100%;
    padding-right: 0;
  }
}
.section__hero_wh .second_col {
  position: relative;
}
.section__hero_wh .second_col img.rond_wh {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 820px) {
  .section__hero_wh .second_col img.rond_wh {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 1280px) {
  .section__hero_wh .second_col {
    width: calc(40% - 20px);
  }
}
@media only screen and (max-width: 820px) {
  .section__hero_wh .second_col {
    width: 100%;
  }
}

.section_features_digital {
  margin-top: 40px;
}
.body-page-wts .slider-agence-section .row {
  background: linear-gradient(to bottom, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 0 40px;
  border-radius: 80px;
}
/* .slider-agence-section{
  background-image: none !important;
  background: linear-gradient(to bottom, #E9F5F5, rgba(255, 255, 255, 0)) !important;
  padding: 24px;
  border-radius: 80px;
} */
.body-page-wts .slider-agence-section  {
  background-image: none !important;
}
.body-page-wts h2 {
  color: #1E3250;
  font-weight: 400;
}
.body-page-wts h2 strong{
  color: #68C5D4;
  font-weight: 400;
}
.body-page-wts .slider-agence-section button.owl-prev , .body-page-wts .slider-agence-section button.owl-next{
  color: #68C5D4 !important;
}
.body-page-wts .slider-agence-section .owl-theme .owl-dots .owl-dot span{
  background: #A5DFE8 !important ;
}
.body-page-wts .slider-agence-section .owl-theme .owl-dots .owl-dot.active span{
  background: #68C5D4 !important;
}
.section_features_digital .features_digital_wrapper .title_wrapper h1 strong, .section_features_digital .features_digital_wrapper .title_wrapper h2 strong {
  display: block;
}
.section_features_digital .features_digital_wrapper .content {
  margin-top: 40px;
}
.section_features_digital .features_digital_wrapper .content p {
  font-size: 18px;
  line-height: 30px;
}
.section_features_digital .features_digital_wrapper .first_col {
  width: 660px;
}
@media only screen and (max-width: 1280px) {
  .section_features_digital .features_digital_wrapper .first_col {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 820px) {
  .section_features_digital .features_digital_wrapper .first_col {
    width: 100%;
  }
}
.section_features_digital .features_digital_wrapper .second_col {
  width: calc(100% - 660px - 20px);
}
@media only screen and (max-width: 1280px) {
  .section_features_digital .features_digital_wrapper .second_col {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 820px) {
  .section_features_digital .features_digital_wrapper .second_col {
    width: 100%;
  }
}
.section_features_digital .features_digital_wrapper .second_col .slideshow-container .post__wrapper .thumb__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
}
.section_features_digital .features_digital_wrapper .second_col .slideshow-container .post__wrapper .thumb__wrapper img {
  display: block;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome_hospitality__section {
  margin-top: 40px;
}
.welcome_hospitality__section h2 {
  width: 720px;
  max-width: 100%;
  margin: 0 auto 60px;
  text-align: center;
}
.welcome_hospitality__section .row:nth-child(2) {
  position: relative;
  width: 1036px;
  margin: auto;
}
@media only screen and (max-width: 1060px) {
  .welcome_hospitality__section .row:nth-child(2) {
    width: 780px;
  }
}
@media only screen and (max-width: 820px) {
  .welcome_hospitality__section .row:nth-child(2) {
    width: 100%;
  }
}
.welcome_hospitality__section .row:nth-child(2)::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #75CAD8;
  position: absolute;
  top: 0;
  right: 50%;
}
@media only screen and (max-width: 820px) {
  .welcome_hospitality__section .row:nth-child(2)::before {
    left: 40px;
    right: inherit;
  }
}
.welcome_hospitality__section  .hospitality__card {
  position: relative;
  width: 460px;
  background: linear-gradient(to bottom, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 24px;
  border-radius: 80px;
  margin-top: -250px;
}
@media only screen and (max-width: 1060px) {
  .welcome_hospitality__section  .hospitality__card {
    width: 360px;
  }
}
@media only screen and (max-width: 820px) {
  .welcome_hospitality__section  .hospitality__card {
    width: calc(100% - 40px - 40px);
    margin-left: auto;
    margin-top: 40px;
  }
}
.welcome_hospitality__section  .row:nth-child(2) div:first-child .hospitality__card  {
  margin-top: 0;
}

@media only screen and (max-width: 1060px) {
  .welcome_hospitality__section  .hospitality__card::before {
    right: -40px;
  }
}
@media only screen and (max-width: 820px) {
  .welcome_hospitality__section  .hospitality__card::before {
    left: -52px;
    top: 150px;
  }
}
.welcome_hospitality__section  .row:nth-child(2) div:nth-child(even) .hospitality__card  {
  margin-left: auto;
}
.welcome_hospitality__section  .row:nth-child(2) div:nth-child(even)::before {
  right: inherit;
  left: -69px;
}
@media only screen and (max-width: 1060px) {
  .welcome_hospitality__section  .hospitality__card:nth-child(even)::before {
    left: -40px;
  }
}
@media only screen and (max-width: 820px) {
  .welcome_hospitality__section  .hospitality__card:nth-child(even)::before {
    left: -52px;
    top: 150px;
  }
}
.welcome_hospitality__section  .hospitality__card img {
  width: 100%;
  height: 360px;
  border-radius: 56px;
  overflow: hidden;
}
@media only screen and (max-width: 1060px) {
  .welcome_hospitality__section  .hospitality__card img {
    height: 260px;
  }
}
.welcome_hospitality__section  .hospitality__card  img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.welcome_hospitality__section  .hospitality__card .title_card_wrapper {
  margin: 20px;
  text-align: center;
}
.welcome_hospitality__section  .hospitality__card .title_card_wrapper h3 {
  font-size: 28px;
  color: var(--blue-color);
  font-weight: var(--font-medium);
}
.welcome_hospitality__section  .hospitality__card .title_card_wrapper span.desciption {
  font-size: 22px;
  font-weight: var(--font-medium);
  color: #263E53;
}
.welcome_hospitality__section  .hospitality__card p {
  text-align: center;
  padding: 0 40px;
  margin-bottom: 20px;
}
.welcome_hospitality__section  .hospitality__card a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: #1C72BD;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  padding: 0 60px;
  text-decoration: none;
  margin: auto;
}

.section_our_clients {
  margin: 60px 0;
  background-image: url(../../img/bg__section_1.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.section_our_clients .title_wrapper {
  text-align: center;
  margin-bottom: 80px;
}
.section_our_clients .carrousel-notre-solution {
  width: 880px;
  max-width: 100%;
  padding: 0 40px;
  margin: auto;
}
.section_our_clients .carrousel-notre-solution .owl-item {
  overflow: hidden;
}
.section_our_clients .carrousel-notre-solution .owl-item .item {
  display: flex;
  justify-content: center;
}
.section_our_clients .carrousel-notre-solution .img-solution {
  align-items: center;
  background-color: #fff;
  border-radius: 37px;
  box-shadow: 0px 3px 44px rgba(0, 0, 0, 0.06);
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.section_our_clients .carrousel-notre-solution .img-solution img {
  width: 60%;
  filter: grayscale(100%);
  transition: filter 0.5s ease;
}
.section_our_clients .carrousel-notre-solution .img-solution:hover img {
  filter: grayscale(0%);
}

.hero_whe {
  background-image: url(../../img/bg__section_1.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.hero_whe .row {
  margin-top: 50px;
  background: linear-gradient(to bottom, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 40px;
  border-radius: 80px;
  display: flex;
  flex-wrap: wrap;
}
.hero_whe .row img {
  width: 530px;
  margin-top: -40px;
  /* margin-left: -205px; */
}
@media only screen and (max-width: 820px) {
  .hero_whe .row img  {
    margin-left: 0;
  }
}
.hero_whe__container p {
  font-size: 18px;
  margin-top: 20px;
}
@media only screen and (max-width: 820px) {
  .hero_whe__container .row_wh .col_wh_6 {
    width: 100%;
  }
}
.hero_whe .box-downhero_whe {
  padding-top: 30px;
}
.extra_hero {
  margin-bottom: 60px;
}
.extra_hero p {
  font-size: 18px;
  margin-top: 20px;
}

.whe_features_section {
  margin-bottom: 60px;
}
.whe_features_section h2 {
  width: 770px;
  max-width: 100%;
  margin: 0 auto 40px;
  text-align: center;
}
.whe_features_section .whe_features_content img {
  margin: auto;
}
.whe_features_section .whe_features_mobile {
  display: none;
}
@media only screen and (max-width: 820px) {
  .whe_features_section .whe_features_mobile {
    display: block;
  }
  .whe_features_section .whe_features_desktop {
    display: none;
  }
}

.features__bornes_wrapper h4.main_title {
  font-size: 27px;
  background-color: #1C72BD;
  font-weight: var(--font-medium);
  height: 56px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 42px;
}
.features__bornes_wrapper h4.main_title.bordred_light {
  background: #fff;
  color: #1E3250;
  border: 1px solid #68C5D4;
}
.features__bornes_wrapper h4.main_title:nth-child(1) {
  margin-top: 60px;
}

@media only screen and (max-width: 820px) {
  .features__bornes_wrapper .row_wh .col_wh_6 {
    width: 100%;
  }
}
.features__bornes_wrapper .row_wh .col_wh_6 .thumb img {
  display: block;
  margin: auto;
}
.box-downfeatures__bornes_wrapper, .features__bornes_wrapper .orderBox1  {
  background: linear-gradient(to right, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 40px;
  border-radius: 60px;
  margin-top: 60px;
}
.features__bornes_wrapper .feature_item_card .title_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.features__bornes_wrapper h3 {
  font-size: 30px;
  color: #4DBDCF;
  font-weight: var(--font-medium);
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: left;
}
.features__bornes_wrapper .feature_item_card p {
  font-size: 18px;
  line-height: 30px;
}
.features__bornes_wrapper .feature_item_card p + p {
  margin-top: 20px;
}
.features__bornes_wrapper .feature_item_card {
  margin-top: 60px;
}
.features__bornes_wrapper .orderBox2 {
  margin-bottom: 60px;
}
.features__bornes_wrapper .col_totem {
  display: flex;
  background: linear-gradient(to right, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 20px 40px;
  border-radius: 60px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 820px) {
  .features__bornes_wrapper .col_totem {
    flex-wrap: wrap;
  }
}
.features__bornes_wrapper .col_totem .thumb img {
  width: 280px;
  min-width: 280px;
  margin-bottom: -60px;
}
@media only screen and (max-width: 820px) {
  .features__bornes_wrapper .col_totem .thumb img {
    margin-bottom: 0px;
  }
}
.features__bornes_wrapper .col_totem .feature_item_card {
  background: none;
}

.hero_wh_it {
  background-image: url(../../img/bg__section_1.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding-top: 80px;
}
.hero_wh_it .content_hero_wh_it {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.hero_wh_it .content_hero_wh_it .col_1_hero_wh_it {
  padding-top: 60px;
  width: calc(100% - 400px);
}
@media only screen and (max-width: 820px) {
  .hero_wh_it .content_hero_wh_it .col_1_hero_wh_it {
    width: 100%;
    padding-top: 0;
    margin-bottom: 40px;
  }
}
.hero_wh_it .content_hero_wh_it .col_2_hero_wh_it {
  width: 350px;
}
.hero_wh_it .content_hero_wh_it .title_wrapper {
  margin-bottom: 40px;
}
.hero_wh_it .content_hero_wh_it .title_wrapper h1 strong:first-child {
  display: block;
}
.hero_wh_it .content_hero_wh_it .content p {
  font-size: 18px;
  line-height: 30px;
}

.functionalities__wrapper {
  margin: 40px 0;
}
.functionalities__wrapper .title_wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.functionalities__wrapper .row {
  background: linear-gradient(to bottom, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 40px;
  border-radius: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.functionalities__wrapper .row img {
  width: 420px;
  height: 420px;
  border-radius: 60px;
  overflow: hidden;
}
@media only screen and (max-width: 820px) {
  .functionalities__wrapper .row img {
    width: 100%;
  }
}
.functionalities__wrapper .row  img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.functionalities__wrapper .row .col_content {
  width: calc(100% - 460px);
}
@media only screen and (max-width: 820px) {
  .functionalities__wrapper .row .col_content {
    width: 100%;
  }
}
.functionalities__wrapper .row .content_card_it {
  margin-bottom: 20px;
}
.functionalities__wrapper .row .content_card_it .title_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.functionalities__wrapper .row h3 {
  font-size: 25px;
  color: #4DBDCF;
  font-weight: var(--font-medium);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;
}
.functionalities__wrapper .row h3 img{
  height: 63px;
    width: 63px;
    min-width: 63px;
}
.functionalities__wrapper .row .content_card_it p {
  font-size: 18px;
}/*# sourceMappingURL=style.css.map */

.features__bornes_wrapper .feature_item_card {
  background: linear-gradient(to right, #E9F5F5, rgba(255, 255, 255, 0));
  padding: 0 37px 40px 37px;
  border-radius: 60px;
}
.slider-wts-section h1{
  color: #1E3250;
  font-weight: 400;
}
.body-page-wh .slider-wts-section h1 strong {
  font-size: 32px;
}
.body-page-wh .slider-wts-section .row > div:first-child  > div {
  top: 50px;
  position: relative;
}
@media only screen and (max-width: 991.5px) {
  .body-page-wh .slider-wts-section .row  div:first-child  > div  {
    top: 100%;
  }
  .features__bornes_wrapper .orderBox2 {
    margin-bottom: 0;
  }
  .hero_whe .box-downhero_whe {
    padding-top: 0;
  }
}
.section-solution.wfo strong{
  color: #68C5D4 !important;
}
.section-solution.wfo h1{
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .welcome_hospitality__section .hospitality__card a {
    padding: 0 8px;
    font-size: 12px;
  }
}